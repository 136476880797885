import React from 'react'
import Layout from "../../../../components/layout";
import HeaderPaginas from '../../../../components/headerPaginas'
import Seo from '../../../../components/SEO/SEO';
import { GrShield, GrDocumentLocked, GrAscend } from 'react-icons/gr';
import { IconContext } from "react-icons"
import CtaServicios from '../../../../components/ctaServicios';


export default function AuditoriasCiber(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Auditorías de ciberseguridad" imagen="/img/cabeceras/auditorias-ciberseguridad-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>Saber cuánto de seguras son tus infraestructuras y servicios IT es fundamental como punto de partida y para obtener una visión del estado de seguridad.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
            <section className="limitador">
                    <p className="parrafo-destacado">Una auditoria es siempre el punto de partida para prevenir los riesgos que afectan a los activos críticos de tu negocio.</p>
                <div className="fila-cards-servicio" id="fila-cards-auditoria">
                    <IconContext.Provider value={{ fill: "#1f1f1f", stroke: "#1f1f1f", color: "#1f1f1f", size: "30%", className: "cpd-icono-svg" }}>
                        <div className="card-servicio">
                            <div className="card-servicio__icono">
                                <GrShield />
                            </div>
                            <div className="card-servicio__info">
                                <p>Determinan de forma exacta el estado de tu seguridad</p>
                            </div>
                        </div>
                        <div className="card-servicio">
                            <div className="card-servicio__icono">
                                <GrDocumentLocked />
                            </div>
                            <div className="card-servicio__info">
                                <p>Documentan en detalle la efectividad de tus sistemas de defensa frente a posibles ciberataques.</p>
                            </div>
                        </div>
                        <div className="card-servicio">
                            <div className="card-servicio__icono">
                                <GrAscend />
                            </div>
                            <div className="card-servicio__info">
                                <p>Indican cómo optimizar los recursos con el objetivo último de mejorar la ciberseguridad de tu empresa</p>
                            </div>
                        </div>
                    </IconContext.Provider>
                </div>
                </section>
                
                <section className="bloques-partidos">
                    <div className="bloque-oscuro">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">Auditoría externa</h2>
                        <p>Es aquella que hace referencia y que se ocupa de conocer el estado de los activos externos a la infraestructura propia y física de la empresa, como la web, el dominio u otros, y en ella se establecen diferentes fases y acciones como:</p>
                        <ul>
                            <li>Recogida de información</li>
                            <li>Scan</li>
                            <li>Detección</li>
                            <li>Explotación</li>
                            <li>Post explotación</li>
                        </ul>
                        </div>
                    </div>
                    <div className="bloque-acento" id="bloque-redes">
                        <div className="overlay-rojo"></div>
                        <div className="limitador contenido__servicio">
                            <h2 className="titulo_contenido">Auditoría interna</h2>
                            <p>Aquella que analiza la seguridad de la parte interna: de tus infraestructuras y equipos de tu sede o sedes. Y que incluye acciones de:</p>
                            <ul>
                                <li>Pentesting</li>
                                <li>Red local y conectividades</li>
                                <li>AD (Active directory)</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <div className="limitador">
                    <p className="parrafo-destacado">Una vez realizada la auditoría, nuestro equipo elaborará un informe completo en el que se exponen todos los riesgos o brechas de seguridad localizadas, así como un plan de mejora personalizado para solucionarlas y que la infraestructura IT de tu empresa quede protegida.</p>
                </div>
                </div>
            <CtaServicios/>
        </Layout>
    )
}